import Div from "@jumbo/shared/Div";
import {
  Button,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const PromptModal = ({
  open,
  onClose,
  onSubmit,
  onChange,
  promptValue,
  isEdit,
  onUpdate,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Div sx={{ ...style, p: 2 }}>
        <Typography variant="h2">
          {isEdit ? "編輯常用指令" : "新增常用指令"}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Stack
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          mb={2}
        >
          <TextField
            name="title"
            label="請輸入標題"
            fullWidth
            value={promptValue ? promptValue.title : ""}
            onChange={onChange}
          />
          <TextField
            multiline
            rows={4}
            name="prompt"
            label="請輸入指令"
            fullWidth
            value={promptValue ? promptValue.prompt : ""}
            onChange={onChange}
          />
        </Stack>
        <Stack width="100%" alignItems="flex-end">
          <Button
            variant="contained"
            onClick={isEdit ? onUpdate : onSubmit}
            sx={{ height: 40 }}
          >
            {isEdit ? "更新" : "新增"}
          </Button>
        </Stack>
      </Div>
    </Modal>
  );
};

export default PromptModal;

PromptModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  promptValue: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
