import http from "app/services";

import {
  ADD_PROMPT,
  ADD_PROMPT_DONE,
  DEL_PROMPT,
  DEL_PROMPT_DONE,
  FCH_USER_PROMPT,
  FCH_USER_PROMPT_DONE,
  HEALTH_CHECK,
  HEALTH_CHECK_DONE,
  HEALTH_CHECK_FAIL,
  UPD_PROMPT,
  UPD_PROMPT_DONE,
} from "../types/app";
import { errorAlert, statusHandler, successAlert } from "./alert";

export const healthCheck = () => {
  return async (dispatch) => {
    dispatch({ type: HEALTH_CHECK });
    const res = await http.get("/api/v1/services");
    if (res.response && res.response.status >= 400) {
      dispatch(errorAlert("Service health check failed"));
      return dispatch({ type: HEALTH_CHECK_FAIL });
    }
    dispatch({ type: HEALTH_CHECK_DONE, payload: { data: res.data } });
  };
};

export const fchUserPrompt = (uId) => {
  return async (dispatch) => {
    dispatch({ type: FCH_USER_PROMPT });
    const res = await http.get(`/api/v1/users/${uId}/prompts`);
    dispatch({ type: FCH_USER_PROMPT_DONE, payload: { data: res.data } });
  };
};

export const addPrompt = (uId, prompt) => {
  return async (dispatch) => {
    dispatch({ type: ADD_PROMPT });
    console.log(prompt);
    const res = await http.post(`/api/v1/users/${uId}/prompts`, prompt);
    const { id } = res.data.payload;
    if (res.response && res.response.status >= 400) {
      dispatch(statusHandler(res.response.status));
      return false;
    }
    const promptData = {
      id,
      prompt: prompt.prompt,
      title: prompt.title,
      user_id: uId,
    };
    dispatch({ type: ADD_PROMPT_DONE, payload: { data: promptData } });
    dispatch(successAlert("新增常用指令成功"));
  };
};

export const delPrompt = (uId, promptId) => {
  return async (dispatch) => {
    dispatch({ type: DEL_PROMPT });
    const res = await http.delete(`/api/v1/users/${uId}/prompts/${promptId}`);
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({ type: DEL_PROMPT_DONE, payload: { id: promptId } });
    dispatch(successAlert("常用指令已刪除"));
  };
};
export const updPrompt = (uId, promptId, prompt) => {
  return async (dispatch) => {
    dispatch({ type: UPD_PROMPT });
    const res = await http.patch(
      `/api/v1/users/${uId}/prompts/${promptId}`,
      prompt,
    );
    if (res.response && res.response.status >= 400) {
      return dispatch(statusHandler(res.response.status));
    }
    dispatch({
      type: UPD_PROMPT_DONE,
      payload: { data: prompt, id: promptId },
    });
    dispatch(successAlert("常用指令已更新"));
  };
};
