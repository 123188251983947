export const HEALTH_CHECK = "APP/HEALTH_CHECK";
export const HEALTH_CHECK_DONE = "APP/HEALTH_CHECK_DONE";
export const HEALTH_CHECK_FAIL = "APP/HEALTH_CHECK_FAIL";

export const FCH_USER_PROMPT = "APP/FCH_USER_PROMPT";
export const FCH_USER_PROMPT_DONE = "APP/FCH_USER_PROMPT_DONE";

export const ADD_PROMPT = "APP/ADD_PROMPT";
export const ADD_PROMPT_DONE = "APP/ADD_PROMPT_DONE";

export const DEL_PROMPT = "APP/DEL_PROMPT";
export const DEL_PROMPT_DONE = "APP/DEL_PROMPT_DONE";

export const UPD_PROMPT = "APP/UPD_PROMPT";
export const UPD_PROMPT_DONE = "APP/UPD_PROMPT_DONE";
