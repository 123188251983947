import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { errorAlert, successAlert } from "app/redux/actions/alert";
import { addPrompt, delPrompt, updPrompt } from "app/redux/actions/app";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PromptModal from "../Modal/PromptModal";

const PromptMenu = () => {
  const prompts = useSelector(({ app }) => app.get("prompts"));
  const user = useSelector(({ app }) => app.get("user"));
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [promptValue, setPromptValue] = useState({
    title: "",
    prompt: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editPromptId, setEditPromptId] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(item.get("prompt"));
    dispatch(successAlert("已複製常用指令"));
    handleClose();
  };

  const handleOpenPromptModal = () => {
    setIsOpen(true);
    handleClose();
  };

  const handleClosePromptModal = () => {
    setIsOpen(false);
    setPromptValue({ title: "", prompt: "" });
    setIsEdit(false);
    setEditPromptId(null);
  };

  const handleChangePrompt = (e) => {
    setPromptValue({ ...promptValue, [e.target.name]: e.target.value });
  };

  const handleSubmitPrompt = async () => {
    const isDuplicate = prompts.some(
      (prompt) => prompt.get("title") === promptValue.title,
    );
    if (isDuplicate) {
      return dispatch(errorAlert("標題已重複"));
    }
    dispatch(addPrompt(user.id, promptValue));
    handleClosePromptModal();
  };

  const handleDelete = (e, promptId) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(delPrompt(user.id, promptId));
  };

  const handleOpenEditPromptModal = (e, promptId, prompt) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEdit(true);
    setEditPromptId(promptId);
    setPromptValue(prompt.toJS());
    handleOpenPromptModal();
  };

  const handleUpdatePrompt = () => {
    dispatch(updPrompt(user.id, editPromptId, promptValue));
    handleClosePromptModal();
  };

  if (!prompts) return null;

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        常用指令集
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {prompts.size === 0 && (
          <MenuItem sx={{ minWidth: 400 }}>
            <Typography variant="body2" textAlign="center">
              目前沒有常用指令
            </Typography>
          </MenuItem>
        )}
        {prompts.size !== 0 &&
          prompts.map((prompt) => (
            <MenuItem key={prompt.get("id")}>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ minWidth: 400 }}
                gap={2}
              >
                <Typography variant="subtitle1">
                  {prompt.get("title") && prompt.get("title").length > 12
                    ? `${prompt.get("title").slice(0, 12)}...`
                    : prompt.get("title")}
                </Typography>
                <Stack flexDirection="row">
                  <IconButton onClick={(e) => handleSelect(e, prompt)}>
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={(e) =>
                      handleOpenEditPromptModal(e, prompt.get("id"), prompt)
                    }
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={(e) => handleDelete(e, prompt.get("id"))}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
            </MenuItem>
          ))}
        <Divider />
        <MenuItem sx={{ py: 0, display: "flex", justifyContent: "center" }}>
          <Button
            fullWidth
            size="small"
            startIcon={<AddIcon />}
            onClick={handleOpenPromptModal}
          >
            新增常用指令
          </Button>
        </MenuItem>
      </Menu>
      <PromptModal
        open={isOpen}
        promptValue={promptValue}
        isEdit={isEdit}
        editPromptId={editPromptId}
        onClose={handleClosePromptModal}
        onSubmit={handleSubmitPrompt}
        onChange={handleChangePrompt}
        onUpdate={handleUpdatePrompt}
      />
    </>
  );
};

export default PromptMenu;
