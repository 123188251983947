import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { config } from "./config/main";
import { fchUserPrompt, healthCheck } from "./redux/actions/app";
import { fchUser, userLogout } from "./redux/actions/auth";
import { fchConversationList } from "./redux/actions/conversation";
import { fchGroups, fchOtherGroups } from "./redux/actions/group";
import { fchKnowledgebaseList } from "./redux/actions/knowledgebase";
import { fchCategoryList } from "./redux/actions/menu";
import { fchModels } from "./redux/actions/model";
import { fchNotifications } from "./redux/actions/notification";
import { fchPluginList } from "./redux/actions/plugin";
import { APP_ACTIONS } from "./utils/constants/appActions";
import routeException from "./utils/constants/routeException";

const AppContext = React.createContext({});

function init(initialValue) {
  return {
    customizerVisibility: initialValue.customizerVisibility,
    containerStyle: initialValue.containerStyle,
  };
}

function appReducer(state, action) {
  switch (action.type) {
    case APP_ACTIONS.SET_CUSTOMIZER_VISIBILITY:
      return {
        ...state,
        customizerVisibility: action.payload,
      };

    case APP_ACTIONS.SET_CONTAINER_STYLE:
      return {
        ...state,
        containerStyle: action.payload,
      };

    case APP_ACTIONS.SET_APP:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

function AppProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isInitialized, setIsInitialized] = useState(false);

  const [app, setApp] = React.useReducer(
    appReducer,
    {
      customizerVisibility: false,
      containerStyle: config.containerStyle,
    },
    init,
  );

  const setCustomizerVisibility = React.useCallback(
    (value) => {
      setApp({ type: APP_ACTIONS.SET_CUSTOMIZER_VISIBILITY, payload: value });
    },
    [setApp],
  );

  const setContainerStyle = React.useCallback(
    (containerStyle) => {
      setApp({
        type: APP_ACTIONS.SET_CONTAINER_STYLE,
        payload: containerStyle,
      });
    },
    [setApp],
  );

  const setAppState = React.useCallback(
    (stateObject) => {
      setApp({ type: APP_ACTIONS.SET_APP, payload: stateObject });
    },
    [setApp],
  );

  const contextValue = React.useMemo(
    () => ({
      ...app,
      setCustomizerVisibility,
      setContainerStyle,
      setAppState,
    }),
    [app],
  );

  const authValidation = () => {
    const storage = localStorage.getItem("ragi");
    if (!storage) {
      return false;
    }
    if (storage) {
      const token = JSON.parse(storage).token;
      const loginData = JSON.parse(atob(token.split(".")[1]));
      const expireTime = loginData.exp;
      if (expireTime < new Date().getTime() / 1000) {
        return false;
      }
    }

    return true;
  };

  const AppInit = async () => {
    try {
      const user = await dispatch(fchUser());
      const { id, group_id, role } = user;
      await dispatch(fchConversationList(id));
      await dispatch(fchKnowledgebaseList(id));
      await dispatch(fchGroups(group_id));
      await dispatch(fchOtherGroups(group_id));
      await dispatch(fchModels());
      await dispatch(fchNotifications(id));
      await dispatch(fchPluginList());
      await dispatch(fchNotifications(id));
      await dispatch(fchPluginList());
      await dispatch(fchUserPrompt(id));
      await dispatch(fchCategoryList());
      if (role === "admin") {
        await dispatch(healthCheck());
      }
      setIsInitialized(true);
    } catch (error) {
      console.error(error);
      navigate("/500", { replace: true });
    }
  };

  useEffect(() => {
    const isException = routeException.includes(pathname);
    const isAuth = authValidation();
    if (!isAuth && !isException) {
      dispatch(userLogout());
      return;
    }
    if (isAuth && !isException && !isInitialized) {
      AppInit();
    }
  }, [dispatch, isInitialized, pathname]);

  useEffect(() => {
    const role = localStorage.getItem("ragi")
      ? JSON.parse(localStorage.getItem("ragi")).data.role
      : null;
    if (role !== "admin" && pathname.includes("/admin")) {
      navigate("/403", { replace: true });
    }
  }, [pathname]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node,
};

export { AppContext };
export default AppProvider;
